import React, { FC, ReactElement, memo } from "react";
// import { BooleanFieldProps, TextField, TextFieldProps } from "ra-ui-materialui";
import { makeStyles } from '@material-ui/core/styles';
import { Badge, Divider, Typography, TypographyProps } from '@material-ui/core'
import get from 'lodash/get';
import { useEffect } from "react";
import { useState } from "react";

import moment from 'moment';
import clsx from "clsx";
import { BooleanField, BooleanFieldProps, FieldProps, TextFieldProps } from "react-admin";


const useStyles = makeStyles((theme) => ({
    nickname: {

    },
    isOnline: {
        borderRadius: "6px",
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText
    },
    splitDateTime: {
        display: 'flex',
        flexDirection: "column",
        padding: "2px 8px",
        "& hr": {
            maxWidth: "60px"
        }

    },
    location: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "12ch",
    }
}));


type AccountStatusProps = { record?: any, source: string }
const AccountStatusField = ({ record }: AccountStatusProps) => {

    const status = record && (record.blockExpires > new Date() ? "retry blocked" : "ok");
    return (<Typography component="span" variant="body2">
        {status}
    </Typography>)

}

const OnlineField = (props: TextFieldProps) => {
    const { record, source } = props;
    const classes = useStyles();


    if (record) {

        const dateTime = (record) => {
            const value = get(record, source)
            if (value) {
                const m = moment(value);
                return [m.format("l"), m.format("HH:mm:ss")]
            } else return [null, null]
        }
        const [date, time] = dateTime(record);
        return (
            <div className={clsx(record.isOnline && classes.isOnline, classes.splitDateTime)}>
                <Typography component="div" variant="body2">
                    {date}
                </Typography>
                <Divider />
                <Typography component="div" variant="caption">
                    {time}
                </Typography>
            </div>
        )
    }
    return null;

}

interface MultiSourceFieldProps extends Omit<FieldProps, "source"> {
    sources: string[],
    separator?: string,
    formatter?: (values: any[]) => string | ReactElement
}

export const MultiSourceField = (props: MultiSourceFieldProps) => {
    let { record, sources, separator = '/', formatter } = props;
    formatter = formatter || (values => values.join(separator));
    if (record) {
        const values = sources.map(s => get(record, s));
        return (
            <Typography component="span" variant="body2">
                {values.join(separator)}
            </Typography>
        )
    } else {
        return null;
    }
}

interface DateTimeFieldProps extends TextFieldProps {
    split?: boolean
}

const DateTimeField = (props: DateTimeFieldProps) => {
    const { record, source, split = false } = props;
    const value = get(record, source)

    const classes = useStyles();
    if (value) {
        const dateTime = (value) => {
            const m = moment(value);
            return [m.format("l"), m.format("HH:mm:ss")]
        }
        const [date, time] = dateTime(value);
        return split
            ? (
                <div className={clsx(classes.splitDateTime)}>
                    <Typography component="div" variant="body2">
                        {date}
                    </Typography>
                    <Divider />
                    <Typography component="div" variant="caption">
                        {time}
                    </Typography>
                </div>
            )
            : (
                <Typography component="span" variant="body2" >
                    {moment(value).format('MMM-DD HH:mm:ss')}
                </Typography>
            )

    }
    return null;
}

// const WalletField = (props: TextFieldProps)=>{
//     const { record, source } = props;
//     const value = get(record, source);
//     if(value && "credits" in value){
//         return(

//         )
//     }

// }

const LocationField = (props: TextFieldProps) => {
    const { record, source } = props;
    const coordinates = get(record, source)?.coordinates;
    const handleClick = (e: any) => {
        e.stopPropagation();
    }
    const classes = useStyles();
    if (coordinates) {
        const norm = `${coordinates[1]}, ${coordinates[0]}`;
        const href = `https://www.google.com/maps/search/?api=1&query=${norm}`;
        return (
            <Typography component="div" variant="body2" onClick={handleClick}>
                <a href={href} target="_blank">

                    <Typography component="div" variant="caption" className={classes.location}>{coordinates[1]}</Typography>
                    <Typography component="div" variant="caption" className={classes.location}>{coordinates[0]}</Typography>
                </a>
            </Typography>
        )
    } else return null
}


export const ellipsisText = (text: string, len: number) => {

    const take = Math.min(text.length, len);
    return text.substr(0, take) + (take < text.length ? "..." : "");
}

interface EllipsesTextFieldProps extends TextFieldProps {
    ellipsis: number
}

const EllipsesTextField = memo<any>(
    ({ className, source, record, variant = "body2", component = "span", ellipsis = 22, emptyText, basePath, ...rest }: EllipsesTextFieldProps) => {
        const rawValue = get(record, source as string)
        const value = rawValue && ellipsisText(rawValue, 22);
        //if(record.deleted)
        return (
            <Typography component={component} variant={variant} className={className} {...rest}>
                {value != null && typeof value !== 'string'
                    ? JSON.stringify(value)
                    : value || emptyText}
            </Typography>
        );
    }
);


interface ColoredBooleanFieldProps extends BooleanFieldProps {
    trueColor: any,
    falseColor: any,
}

const ColoredBooleanField = (props: ColoredBooleanFieldProps) => {
    const { trueColor, falseColor, source, record } = props;
    const value = get(record, source as string);
    return <BooleanField {...props} style={{ color: value ? trueColor : falseColor }} />
}



export { AccountStatusField, EllipsesTextField, OnlineField, LocationField, DateTimeField, ColoredBooleanField }