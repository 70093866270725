import { Box, Card, CardContent, CardHeader, Divider, Grid, Link, Typography } from "@material-ui/core";
import { FormWithRedirect } from "ra-core";
import {
    BooleanInput,
    DateInput,
    Edit,
    NumberInput,
    TextInput,
    EditProps,
    DateTimeInput,
    AutocompleteInput,
    SaveButton,
    SingleFieldList
} from "ra-ui-materialui";
import {
    email,
    ReferenceInput,
    required,
    Toolbar,
    SelectInput,
    ReferenceManyField,
    DeleteButton
} from "react-admin";

import TagsField from "../../components/TagsField";
import ProfileSelectInput from "./ProfileSelectInput";
import UserEditToolbar from "./UserEditToolbar";
import photoUrl from "../../utils/photoUrl";
import ImageFieldExtWithLink from "../../components/ImageFieldExtWithLink";
import UserEditButtonsToolbar from './UserEditButtonsToolbar';
import ProfileMultiSelectInput from "./ProfileMultiSelectInput";
import AdminBlockedWithConfirmation from "./AdminBlockedWithConfirmation";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import UserDeleteWithControl from './UserDeleteWithControl';
import CurrencySelector from "../../components/CurrencySelector";
import { LANGUAGES } from "../../utils/constants";

const userProfileURL = `${import.meta.env.VITE_FRONTEND_URL}/profile/`;

const sanitizeToolbarProps = (({
    children,
    alwaysEnableSaveButton,
    className,
    classes,
    handleSubmitWithRedirect,
    handleSubmit,
    invalid,
    mutationMode,
    pristine,
    saving,
    submitOnEnter,
    redirect,
    basePath,
    record,
    resource,
    undoable,
    width
}: any) => ({
    children, alwaysEnableSaveButton, className, classes,
    handleSubmitWithRedirect, handleSubmit, invalid, mutationMode, pristine, saving, submitOnEnter,
    redirect, basePath, record, resource, undoable, width
}))

export const UserEdit = (props: EditProps) => (
    <Edit {...props}>
        <UserForm />
    </Edit>
);

const UserForm = (props: any) => {

    return (
        <FormWithRedirect
            {...props}

            //validate={validatePasswords}
            render={(formProps: any) => (

                <Box>

                    <Box>
                        <ReferenceManyField record={formProps.record} filter={{ moderation: 'accepted' }} source="id"
                            reference="photo" target="user">
                            <SingleFieldList linkType={false}>
                                <ImageFieldExtWithLink source="id" label="Image"
                                    getSrc={(photo: any) => photoUrl(photo, 'full')} />
                            </SingleFieldList>
                        </ReferenceManyField>
                    </Box>
                    <Box>
                        <UserEditButtonsToolbar {...formProps} />
                    </Box>
                    <Box mt={2} mx={1}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Card variant="outlined">
                                            <Grid container spacing={1}>
                                                <CardHeader title={"Account info"} />
                                                <Link href={userProfileURL + formProps.record.id} target="_blank"
                                                    color="inherit"
                                                    variant="inherit">
                                                    <OpenInNewIcon style={{ marginTop: '6px' }} />
                                                </Link>
                                            </Grid>
                                            <CardContent>
                                                <TextInput variant="outlined" type="email" source="email" label="Email"
                                                    fullWidth helperText={false}
                                                    validate={[email(), required()]} />

                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={4} xl={4}>
                                                        <BooleanInput source="profileValid" helperText={false} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4} xl={4}>
                                                        <BooleanInput source="verified" helperText={false} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4} xl={4}>
                                                        <BooleanInput source="fake" helperText={false} />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={1}>
                                                    <UserDeleteWithControl
                                                        source="deleted"
                                                        helperText={false}
                                                        record={formProps.record}
                                                    />
                                                    {formProps.record.deleted && <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={6} xl={6}>
                                                            <DateInput variant="outlined" source="deletedAt"
                                                                label="Deleted at" fullWidth helperText={false}
                                                                disabled />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} xl={6}>
                                                            <TextInput variant="outlined" source="deletedBy"
                                                                label="Deleted by" fullWidth helperText={false}
                                                                disabled />
                                                        </Grid>
                                                    </Grid>}
                                                </Grid>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={12} xl={12}>
                                                        <AdminBlockedWithConfirmation record={formProps.record}
                                                            source="adminBlocked"
                                                            label="Blocked" />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} xl={12}>
                                                        <TextInput variant="outlined" source="adminData.notes"
                                                            label="AdminData Notes" fullWidth helperText={false}
                                                            multiline />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={3} xl={3}>
                                                        <TextInput variant="outlined" source="verification"
                                                            label='Verification' helperText={false} fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12} sm={9} xl={9}>
                                                        {/* <ArrayField source="externalLogins" record={formProps.record} fieldKey="provider" label={"Providers"}>
                                                            <SingleFieldList>
                                                                <ChipField source="provider" />
                                                            </SingleFieldList>

                                                        </ArrayField> */}
                                                        <TagsField source="externalLogins" record={formProps.record}
                                                            fieldKey="provider" />
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={6} xl={6}>
                                                        <NumberInput variant="outlined" source="loginAttempts"
                                                            label="Login Attempts" helperText={false}
                                                            fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} xl={6}>
                                                        <DateTimeInput variant="outlined" source="blockExpires"
                                                            label="Block expires" helperText={false}
                                                            fullWidth />
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={6} xl={6}>
                                                        <BooleanInput source="isOnline"
                                                            label="Is online" helperText={false} fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} xl={6}>
                                                        <DateTimeInput variant="outlined" source="lastSeen"
                                                            label="Last Seen" helperText={false} fullWidth />
                                                        <DateTimeInput variant="outlined" source="onlineTime"
                                                            label="Online Time" helperText={false} fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} xl={6}>
                                                        <DateTimeInput variant="outlined" source="createdAt"
                                                            label="Created at" helperText={false} fullWidth
                                                            disabled />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} xl={6}>
                                                        <DateTimeInput variant="outlined" source="updatedAt"
                                                            label="Updated at" helperText={false} fullWidth
                                                            disabled />
                                                    </Grid>
                                                </Grid>


                                                <SelectInput variant="outlined" source='role' label="Role"
                                                    helperText={false} fullWidth
                                                    choices={[
                                                        { id: 'user', name: "User" },
                                                        { id: 'admin', name: "Admin" },
                                                    ]}
                                                />
                                                {/* <LocationField source="location" />  */}

                                            </CardContent>
                                            <Toolbar {...sanitizeToolbarProps(formProps)}>
                                                <SaveButton label="Save" redirect={false} />
                                            </Toolbar>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Card variant="outlined">
                                            <CardHeader title={"Credits"} />
                                            <CardContent>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6}>
                                                        <NumberInput variant="outlined" source="wallet.credits"
                                                            label="Credits" helperText={false}
                                                            fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <NumberInput variant="outlined" source="wallet.rewards"
                                                            label="Rewards" helperText={false}
                                                            fullWidth />
                                                    </Grid>
                                                </Grid>

                                                <DateTimeInput variant="outlined" source="boosted"
                                                    label="Boosted at" helperText={false}
                                                    fullWidth />

                                            </CardContent>
                                            <CardHeader title={"Premium"} />
                                            <CardContent>
                                                <Grid container spacing={1}>

                                                    <Grid item container xs={12} sm={6} xl={6}>
                                                        <Grid item xs={6}>
                                                            <BooleanInput source="premium.isActive" label="Is active"
                                                                disabled helperText={false} fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <BooleanInput variant="outlined" source="premium.willAutoRenew"
                                                                label='Will Auto Renew' helperText={false}
                                                                fullWidth />
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} xl={6}>
                                                        <ReferenceInput variant="outlined" label="Plan"
                                                            source="premium.product" helperText={false}
                                                            reference="SubscriptionProduct" fullWidth>
                                                            <SelectInput optionText="title" />
                                                        </ReferenceInput>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} xl={6}>
                                                        <DateTimeInput variant="outlined" source="premium.purchasedAt"
                                                            label='Purchased at' helperText={false}
                                                            fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} xl={6}>

                                                        <DateTimeInput variant="outlined" source="premium.expiresAt"
                                                            label='Expires at' helperText={false} fullWidth />
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} xl={6}>
                                                        <NumberInput variant="outlined" source="premium.price"
                                                            label='Price' helperText={false}
                                                            fullWidth />


                                                    </Grid>
                                                    <Grid item xs={12} sm={6} xl={6}>
                                                        <CurrencySelector variant="outlined" source="premium.currency"
                                                            label='Currency' helperText={false}
                                                            fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} xl={6}>
                                                        <TextInput variant="outlined" source="premium.provider" disabled
                                                            label='Provider' helperText={false}
                                                            fullWidth />
                                                    </Grid>
                                                    <Divider />

                                                </Grid>
                                            </CardContent>
                                            <Toolbar {...sanitizeToolbarProps(formProps)}>
                                                <SaveButton label="Save" redirect={false} />
                                            </Toolbar>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Card variant="outlined">
                                            <CardHeader title={"Settings"} />
                                            <CardContent>
                                                <SelectInput variant="outlined" source='settings.accountVisibility'
                                                    label="Account visibility"
                                                    fullWidth helperText={false} choices={[
                                                        { id: 'all', name: "To all" },
                                                        { id: 'registered', name: "To registered users" },
                                                        { id: 'hidden', name: "Hidden" },
                                                        { id: 'deactivated', name: "Deactivated" },

                                                    ]} />
                                                <SelectInput variant="outlined" source='settings.uiLang'
                                                    label="UI language"
                                                    helperText={false} fullWidth
                                                    choices={LANGUAGES} />
                                                <CurrencySelector variant="outlined" source='settings.currency' label="Currency" helperText={false} fullWidth />

                                                <ProfileSelectInput source="settings.notifications.messagesInterval"
                                                    localePath="notifications:messagesInterval"
                                                    label="Messages interval notifications"
                                                    helperText={false}
                                                    fullWidth />
                                                <ProfileSelectInput source="settings.notifications.visitorsInterval"
                                                    localePath="notifications:visitorsInterval"
                                                    label="Visitors interval notifications"
                                                    helperText={false}
                                                    fullWidth />
                                            </CardContent>
                                            <Toolbar {...sanitizeToolbarProps(formProps)}>
                                                <SaveButton label="Save" redirect={false} />
                                            </Toolbar>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Card variant="outlined">
                                            <CardHeader title={"Location"} />
                                            <CardContent>
                                                <Typography variant="h6">Search Location</Typography>
                                                <BooleanInput source="freezeSearchLocation" helperText={false} />
                                                <SelectInput label="Source" source="searchLocation.source" helperText={false} variant="outlined"
                                                    choices={[
                                                        { id: 'location', name: "Location" },
                                                        { id: 'city', name: "City" },
                                                        { id: 'ipLocation', name: "Ip Location" }
                                                    ]} />
                                                <Box display={"grid"} gridTemplateColumns={"1fr 1fr"} width={"100%"}>
                                                    <NumberInput label="Latitude" source="searchLocation.coordinates[1]" helperText={false} variant="outlined" />
                                                    <NumberInput label="Longitude" source="searchLocation.coordinates[0]" helperText={false} variant="outlined" />
                                                </Box>

                                                <Box component={"p"} />
                                                <Typography variant="h6">Location</Typography>
                                                <NumberInput label="Latitude" source="location.coordinates[1]" helperText={false} variant="outlined" style={{ width: "50%" }} />
                                                <NumberInput label="Longitude" source="location.coordinates[0]" helperText={false} variant="outlined" style={{ width: "50%" }} />
                                            </CardContent>
                                            <Toolbar {...sanitizeToolbarProps(formProps)}>
                                                <SaveButton label="Save" redirect={false} />
                                            </Toolbar>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid item xs={12} sm={12} md={6}>
                                <Card variant="outlined">
                                    <CardHeader title={"Profile info"} />
                                    <CardContent>

                                        {/* <Box flex={1}> */}
                                        {/* <Typography variant="h6" gutterBottom>Profile info</Typography> */}

                                        <TextInput variant="outlined" source="profile.nickname" label="Nickname"
                                            helperText={false} fullWidth />
                                        <DateInput variant="outlined" source="profile.dob" label="DOB"
                                            helperText={false} fullWidth />
                                        <TextInput variant="outlined" source="profile.age" label="Age"
                                            helperText={false} fullWidth
                                            disabled />
                                        <ProfileSelectInput source="profile.gender" label="Gender" helperText={false}
                                            fullWidth />
                                        <ProfileSelectInput source="profile.datingAs" label="Dating as"
                                            allowEmpty={true} helperText={false} fullWidth />
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <BooleanInput source="profile.isSponsor" label="Is sponsor"
                                                    helperText={false} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <BooleanInput source="profile.isSponsorLock" label="Is sponsor lock"
                                                    helperText={false} />
                                            </Grid>
                                        </Grid>
                                        <ProfileSelectInput source="profile.sexOrientation" label="Sex orientation"
                                            helperText={false} fullWidth />
                                        <TextInput variant="outlined" source="profile.slogan" label="Slogan" multiline
                                            rows={2} helperText={false} fullWidth />
                                        <TextInput variant="outlined" source="profile.about" label="About" multiline
                                            rows={4} helperText={false} fullWidth />
                                        <ReferenceInput variant="outlined" label="City" source="profile.city"
                                            helperText={false}
                                            reference="city" filterToQuery={txt => ({ name: txt })} fullWidth>
                                            <AutocompleteInput optionText="name" />
                                        </ReferenceInput>
                                        <ProfileMultiSelectInput source='profile.languages' label="Languages"
                                            fullWidth />
                                        <ProfileSelectInput source="profile.purpose" label="Purpose" allowEmpty={true}
                                            helperText={false} fullWidth />
                                        <ProfileSelectInput source="profile.familyStatus" label="Family status"
                                            helperText={false} fullWidth />
                                        <ProfileSelectInput source="profile.children" label="Children"
                                            helperText={false} fullWidth />
                                        <ProfileSelectInput source="profile.education" label="Education"
                                            helperText={false} fullWidth />
                                        <ProfileSelectInput source="profile.occupation" label="Occupation"
                                            helperText={false} fullWidth />
                                        <ProfileSelectInput source="profile.availableTime" label="Available time"
                                            helperText={false} fullWidth />
                                        <ProfileSelectInput source="profile.incomeLevel" label="Income level"
                                            allowEmpty={true} fullWidth />
                                        <ProfileMultiSelectInput source='profile.hobbies' label="Hobbies" fullWidth />
                                        <ProfileSelectInput source="profile.bdsmRelations" label="BDSM relations"
                                            helperText={false} fullWidth />
                                        <ProfileSelectInput source="profile.sponsorType" label="Sponsor type"
                                            allowEmpty={true} helperText={false} fullWidth />
                                        <ProfileSelectInput source="profile.sponsorExpectations"
                                            label="Sponsor expectations" allowEmpty={true}
                                            helperText={false} fullWidth />
                                        <NumberInput variant="outlined" source="profile.searchAge.min"
                                            label="Search age min" helperText={false} fullWidth
                                            validate={[required()]} />
                                        <NumberInput variant="outlined" source="profile.searchAge.max"
                                            label="Search age max" helperText={false} fullWidth
                                            validate={[required()]} />
                                        <ProfileMultiSelectInput source='profile.interestedIn' label="Interested in"
                                            fullWidth />
                                        <ProfileSelectInput source="profile.smoking" label="Smoking" helperText={false}
                                            fullWidth />
                                        <ProfileSelectInput source="profile.drinking" label="Drinking"
                                            helperText={false} fullWidth />
                                        <ProfileSelectInput source="profile.narcotics" label="Narcotics"
                                            helperText={false} fullWidth />
                                        <ProfileSelectInput source="profile.origin" label="Origin" helperText={false}
                                            fullWidth />
                                        <ProfileSelectInput source="profile.religion" label="Religion"
                                            helperText={false} fullWidth />
                                        <ProfileSelectInput source="profile.ethnicity" label="Ethnicity"
                                            helperText={false} fullWidth />
                                        <ProfileSelectInput source="profile.bodyType" label="Body type"
                                            helperText={false} fullWidth />
                                        <TextInput variant="outlined" source="profile.height" label="Height"
                                            type={"number"} helperText={false} fullWidth />
                                        <TextInput variant="outlined" source="profile.weight" label="Weight"
                                            type={"number"} helperText={false} fullWidth />
                                        <ProfileSelectInput source="profile.hair" label="Hair" helperText={false}
                                            fullWidth />
                                        <ProfileSelectInput source="profile.eyes" label="Eyes" helperText={false}
                                            fullWidth />
                                        <ReferenceInput variant="outlined" label="Mood"
                                            source="profile.mood" helperText={false}
                                            reference="mood" fullWidth>
                                            <SelectInput optionText="id" />
                                        </ReferenceInput>
                                        {/* <ProfileSelectInput source="profile.moods" label="Mood" helperText={false}
                                            allowEmpty={true}
                                            fullWidth /> */}
                                        <DateTimeInput variant="outlined" source="profile.moodExpire"
                                            label="Edit MoodExpire Time" helperText={false} fullWidth />
                                        {/* </Box> */}
                                    </CardContent>
                                    <Toolbar {...sanitizeToolbarProps(formProps)}>
                                        <SaveButton label="Save" redirect={false} />
                                    </Toolbar>
                                </Card>
                            </Grid>

                        </Grid>
                        {/* </Box> */}

                    </Box>
                    {/* <Toolbar
                        record={formProps.record}
                        basePath={formProps.basePath}
                        undoable={true}
                        invalid={formProps.invalid}
                        handleSubmit={formProps.handleSubmit}
                        saving={formProps.saving}
                        resource="user"
                    /> */}
                    <UserEditToolbar {...formProps} />
                </Box>

            )}
        />
    )

}

const requiredValidate = [required()];
